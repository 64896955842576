module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/build/repo/src/intl"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"","environment":"production","enabled":"","denyUrls":[{},{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"c3SHwxcW3i2aJwXUmsoHMkRqvOP6dRgE","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000},
    },{
      plugin: require('../node_modules/@arfabrands/gatsby-theme-assembly/gatsby-browser.js'),
      options: {"plugins":[],"contentfulConfig":{"spaceId":"imbw84wm8wpn","accessToken":"LDw8yCapyuQ9J_5HzAMXm4nXzSfcz-yBdxhACtF8CuY","host":"cdn.contentful.com","environment":"master"},"intlConfig":{"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/build/repo/src/intl"},"siteMetadata":{"title":"August","siteUrl":"https://www.itsaugust.co","description":"Reimagining Periods.","social":{"facebook":"itsaugust","instagram":"itsaugust","pinterest":"itsaugust"},"instagram":"@itsaugust"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
